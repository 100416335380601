import react from "react";
import "./Navbar.css";

export default function Navbar() {
  const email = "mohammadsleiman30@gmail.com";
  const subject = "Hello Mohammad";

  return (
    <div className="nav-container">
      <ul className="menu">
        <li className="logo">Mohammad Sleiman</li>
      </ul>
      <ul className="menu">
        <a
          className="menu-link"
          href="https://www.github.com/mohammadsleiman"
          target="_blank"
        >
          <li className="item">github</li>
        </a>
        <a
          className="menu-link"
          href="https://www.linkedin.com/in/mohammadsleiman"
          target="_blank"
        >
          <li className="item">linkedIn</li>
        </a>
        <a className="menu-link" href="mailto:mohammadsleiman30@gmail.com">
          <li className="item">contact</li>
        </a>
      </ul>
    </div>
  );
}
