import react from "react";
import "./Header.css";

const tagline =
  "I'm a software developer who has recently graduated from San Jose State University (B.S. Computer Science) '22 looking for a software engineer role. Ever since I was a kid, I have been extremely passionate about technology and how it could be used to solve the problems around me. Motivated by this goal, in college, I worked on a few projects/startups: Haven and Netvyne. Through my experiences, I have become further interested in applying my skillset at a larger scale, so I interned at SAP and UKG, where I worked on building end-to-end full-stack web applications. I am looking to pursue a career as a software engineer at a company where I can build and contribute to products which have long-lasting positive societal impact";
export default function Header() {
  return (
    <div className="header-container">
      <div className="centered-header-container">
        <div className="header-item">
          <img
            className="img-profile"
            src="https://avatars.githubusercontent.com/u/6894904?v=4"
            alt="Me"
          ></img>
        </div>
        <div className="header-text-container">
          <div className="text-greeting-container">
            <h2 className="text-greeting-name">Hi I'm Mohammad</h2>
            <h2 className="text-greeting-name-emoji">👋</h2>
          </div>
          <p className="text-personal-description">
            Software developer & recent college grad, passionate about building
            products that have a positive societal impact
          </p>

          <p className="text-personal-history">{tagline}</p>
          <p className="text-personal-location">
            Currently living in San Jose 🏙️
          </p>
        </div>
      </div>
    </div>
  );
}
