import react from "react";
//import "./ExperienceCard.css";
import "./ExperienceCard.css";
import githubLogo from "../media/githublogo.png";
import linkedinLogo from "../media/linkedinlogo.webp";

export default function ExperienceCard(props) {
  return (
    <a className="clickable-container" href={props.experienceData.link}>
      <div className="card-container">
        <div className="text-container">
          <div>
            <p className="experience-name-text">{props.experienceData.name}</p>
            <p className="experience-location-text">
              {props.experienceData.location}
            </p>
          </div>

          <p>{props.experienceData.description}</p>
          <div className="experience-buttons-container">
            {props.experienceData.github ? (
              <a href={props.experienceData.github}>
                <img src={githubLogo} className="img-button" height="40px" />
              </a>
            ) : null}
            {props.experienceData.linkedin ? (
              <a href={props.experienceData.linkedin}>
                <img
                  src={linkedinLogo}
                  className="img-button"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                  height="20px"
                />
              </a>
            ) : null}
          </div>
        </div>
        <div className="image-container">
          <img className="experience-image" src={props.experienceData.image} />
        </div>
      </div>
    </a>
  );
}
