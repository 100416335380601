import react from "react";
import "./Footer.css";
import imgLinkedin from "../media/linkedinicon.png";

export default function Footer() {
  return (
    <div>
      <h1>Let's chat!</h1>
      <p>Feel free to shoot me a message to get in touch</p>
      <h4>mohammadsleiman30@gmail.com</h4>
      <h4>
        <a
          className="footer-link"
          target="_blank"
          href="https://linkedin.com/in/mohammadsleiman"
        >
          <img
            className="footer-img"
            src={imgLinkedin}
            height="40px"
            width="40px"
          ></img>
        </a>
      </h4>
    </div>
  );
}
